import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuditLog, useEnvService } from 'admin-portal-shared-services';
import { useCategoryContext } from 'context/CategoryContext';
import {
  NewCategoryState,
  resetNewCategoryFields,
  useNewCategoryContext,
} from 'context/new-category';
import { showToast, useToastContext } from 'context/toast-context';
// eslint-disable-next-line import/no-extraneous-dependencies
import { APP_NAME, AuditLogEntities, AuditLogOperations, CategoryPages } from 'constants/audit-log';
import { MAX_ENABLED_COLLECTION_CATEGORIES } from 'constants/collection';
import { useStoreContext } from 'context/store-context';
import { useToggleContext } from 'context/toggle-context';
import { Location } from 'history';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics';
import useZoneLanguages from 'hooks/useZoneLanguages';
import _ from 'lodash';
import { GET_CATEGORIES } from 'pages/CategoryHome/useCategoryHome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import uploadFile from 'services/file';
import { ItemAPIResponse } from 'types';
import { isCategoryClubB } from 'utils/category';
import getSortOrder from 'utils/sortOrder/getSortOrder';

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory($category: CategoryInput!, $store: String, $zone: String) {
    createCategory(category: $category, store: $store, zone: $zone) {
      code
      success
      message
    }
  }
`;

type NewCategoryItem = Partial<Pick<ItemAPIResponse, 'vendorItemId' | 'vendorId' | 'sortOrder'>>;
type NewCategory = {
  name: string;
  enabled: boolean;
  image: string | null;
  restricted: string[] | null;
  storeCategoryId: string;
  group?: string;
  items: NewCategoryItem[] | null;
  translations: {
    language: string;
    name: string;
  }[];
  sortOrder?: number;
  parentId?: string[];
};

const useNewCategory = (): {
  isConfirmModalOpen: boolean;
  handleOpenConfirmModal: () => void;
  handleCloseConfirmModal: () => void;
  handleDiscardClick: () => void;
  handleCreateClick: () => void;
  isModalOpen: boolean;
  handleConfirmClick: () => void;
  handleCancelClick: () => void;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleBlockedNavigation: (location: Location) => boolean;
  showDiscardModal: boolean;
  success: boolean;
  handleSave: () => Promise<void>;
  isSaving: boolean;
} => {
  const env = useEnvService().getEnv();
  const auditLog = useAuditLog(APP_NAME);
  const { dispatch, state } = useNewCategoryContext();
  const { t: translate } = useTranslation();
  const { acceptedLanguages, defaultLanguage, country } = useZoneLanguages();
  const { state: storeState } = useStoreContext();
  // istanbul ignore next
  const handleSuccess = () => {
    dispatch({ type: 'success' });
    dispatch({ type: 'fields reset' });
    history.push('/category-marketplace');
  };
  const [create, { loading, data }] = useMutation(CREATE_CATEGORY_MUTATION, {
    onCompleted: handleSuccess,
    onError: () => {
      showToast(toastDispatch, {
        severity: 'error',
        text: translate('Toasts.errorCreateCategory'),
      });
    },
  });
  const {
    state: { categories },
  } = useCategoryContext();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const defaultLanguageCode = `${defaultLanguage}-${country}`;
  const { success } = state.status;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { dispatch: toastDispatch } = useToastContext();
  const history = useHistory();
  const {
    state: { useStoreCategoryIdToggle },
  } = useToggleContext();
  const {
    triggerEvent,
    parseCategoryEnabled,
    parseCategoryType,
    parseCategoryProducts,
    parseCategoryLevelByParents,
    parseCountry,
  } = useSegmentAnalytics();

  const { data: enabledCollectionCategories, refetch } = useQuery(GET_CATEGORIES, {
    variables: {
      store: storeState.selectedStore?.storeId,
      zone: country,
      group: 'COLLECTION',
      includeDisabled: false,
    },
    notifyOnNetworkStatusChange: true,
    skip: !storeState.selectedStore?.storeId || state.categoryGroup !== 'COLLECTION',
  });
  // istanbul ignore next
  useEffect(() => {
    if (data?.createCategory) {
      const { success: isSuccessful } = data.createCategory;
      showToast(toastDispatch, {
        severity: isSuccessful ? 'success' : 'error',
        text: isSuccessful
          ? translate('Toasts.successfulCreateCategory')
          : translate('Toasts.errorCreateCategory'),
      });
    }
  }, [data, toastDispatch]);
  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      // Navigate to the previous blocked location
      resetNewCategoryFields(dispatch);
      history.push(nextLocation?.pathname);
    }
  }, [confirmedNavigation, nextLocation, history, dispatch]);

  useEffect(() => {
    return () => {
      resetNewCategoryFields(dispatch);
    };
  }, [dispatch]);

  // istanbul ignore next
  const handleBlockedNavigation = (location: Location<unknown>): boolean => {
    if (!confirmedNavigation) {
      setNextLocation(location);
      setShowDiscardModal(true);
      return false;
    }
    return true;
  };

  const handleDiscardClick = () => {
    history.push('/category-marketplace');
  };

  const handleConfirmClick = () => {
    setConfirmedNavigation(true);
  };

  const handleCancelClick = () => {
    setShowDiscardModal(false);
  };

  const checkPremiumKey = (category: NewCategoryState) => {
    let hasErrors = false;
    let actionType = '';

    if (category.categoryType === 'premium') {
      const premiumKey = category?.premium;
      if (!premiumKey?.trim()) {
        hasErrors = true;
        actionType = 'empty field error';
      } else if (isCategoryClubB([premiumKey?.trim()])) {
        hasErrors = true;
        actionType = 'invalid premium key';
      }
    }

    return { hasErrors, actionType };
  };

  // istanbul ignore next
  const handleValidate = () => {
    const { categoryLevel, position } = state;
    let sortOrder = null;
    if (categoryLevel === 1 && Object.values(categories).length) {
      sortOrder = getSortOrder(Object.values(categories), position);
    }
    const category = { ...state, sortOrder };
    let hasInputError = false;
    _.each(_.keys(category), (field: string) => {
      switch (field) {
        case 'translations': {
          const nameFields = Object.values(category.translations);
          // Checks to see if any of the name fields are empty
          if (nameFields.length !== acceptedLanguages.length || nameFields.includes('')) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'categoryNames' },
            });
            hasInputError = true;
          }
          break;
        }
        case 'storeCategoryId': {
          if (useStoreCategoryIdToggle && !category[field].trim().length) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'storeCategoryId' },
            });
            hasInputError = true;
          }
          break;
        }
        case 'categoryLevel': {
          if (category[field] > 1 && !category.parentCategories.length) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'parentCategories' },
            });
            hasInputError = true;
          }
          break;
        }
        case 'categoryType': {
          const { hasErrors, actionType } = checkPremiumKey(category);

          if (hasErrors) {
            dispatch({ type: actionType, payload: { error: 'premium' } });
          }

          break;
        }
        case 'enabled': {
          refetch();
          if (
            category['categoryGroup'] === 'COLLECTION' &&
            category[field] === true &&
            enabledCollectionCategories?.categories?.length >= MAX_ENABLED_COLLECTION_CATEGORIES
          ) {
            dispatch({
              type: 'max enabled collection categories error',
              payload: { error: 'status' },
            });
            hasInputError = true;
          }
        }
        default:
      }
    });
    if (hasInputError) {
      handleCloseConfirmModal();
    } else {
      handleCloseModal();
      handleOpenConfirmModal();
    }
  };

  const handleCreateClick = () => {
    handleValidate();
  };

  const getImage = async (): Promise<string | null> => {
    // TODO: Toggle loading state while waiting for image upload
    const imageUrl = state.imgFile ? await uploadFile(state.imgFile, env) : null;

    return imageUrl;
  };
  const getRestricted = (): string[] | null => (state.premium ? [state.premium] : null);
  const getItems = (): NewCategoryItem[] | null =>
    state.items.length
      ? state.items.map((item, index) => ({
          vendorItemId: item.vendorItemId,
          vendorId: item.vendorId,
          sortOrder: index + 1,
        }))
      : null;
  const getTranslations = (): { language: string; name: string }[] =>
    Object.keys(state.translations).map((key) => ({
      language: key,
      name: state.translations[key],
    }));
  const addParentId = (newCategory: NewCategory): void => {
    // Multiple category creation to be handled in the resolver
    if (state.parentCategories.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newCategory.parentId = state.parentCategories.map(({ id }) => id);
      newCategory.group = undefined;
    }
  };
  const addSortOrder = (newCategory: NewCategory): void => {
    // Return undefined for position bottom and handle sort order in GraphQL service
    if (state.position === 'top') {
      newCategory.sortOrder = 0;
    }
  };

  const createCategory = async (): Promise<void> => {
    const newCategory: NewCategory = {
      name: state.translations[defaultLanguageCode],
      enabled: state.enabled,
      group: state.categoryGroup,
      image: await getImage(),
      restricted: getRestricted(),
      storeCategoryId: state.storeCategoryId,
      items: getItems(),
      translations: getTranslations(),
    };
    addParentId(newCategory);
    addSortOrder(newCategory);

    await create({
      variables: {
        category: newCategory,
        store: storeState.selectedStore?.storeId,
        zone: country,
      },
    });
  };

  const saveNewCategoryOnAuditLog = async (): Promise<void> => {
    await auditLog({
      entity: AuditLogEntities.CATEGORIES,
      entityId: '-',
      metadata: {
        storeId: storeState.selectedStore?.storeId as string,
        sourcePage: CategoryPages.CATEGORY_CREATION,
        categoryName: state.translations[defaultLanguageCode],
        totalOfItems: state?.items?.length.toString(),
      },
      operation: AuditLogOperations.INSERT,
      country,
    });
  };

  const triggerSegmentEvent = (): void => {
    triggerEvent('newCategoryCreated', {
      category_country: parseCountry(country),
      category_level: parseCategoryLevelByParents(state.parentCategories),
      category_name: state.translations[defaultLanguageCode],
      category_status: parseCategoryEnabled(state.enabled),
      category_store: storeState?.selectedStore?.name,
      category_type: parseCategoryType(state.premium),
      products: parseCategoryProducts(state.items),
    });
  };

  const handleSave = async () => {
    try {
      await createCategory();
      await saveNewCategoryOnAuditLog();
      triggerSegmentEvent();
    } catch (err) {
      console.error(err);
    } finally {
      handleCloseModal();
      handleCloseConfirmModal();
    }
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };
  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };
  return {
    isConfirmModalOpen,
    handleOpenConfirmModal,
    handleCloseConfirmModal,
    handleDiscardClick,
    handleCreateClick,
    isModalOpen,
    handleCloseModal,
    handleOpenModal,
    showDiscardModal,
    handleCancelClick,
    handleConfirmClick,
    handleBlockedNavigation,
    success,
    handleSave,
    isSaving: loading,
  };
};

export default useNewCategory;
